<template>
    <div class="individual-format" v-if="translates && translates[langUrl]">
        <div class="individual-format__container">
            <img
                class="individual-format__image"
                src="../../../assets/UsersThin.svg"
                alt="users" />
            <div class="individual-format__text-container">
                <div class="individual-format__name">{{ translates[langUrl].type_1_title[lang] }}</div>
                <div class="individual-format__description">{{ translates[langUrl].type_1_description[lang] }}</div>
            </div>
        </div>
        <div class="individual-format__container">
            <img
                class="individual-format__image"
                src="../../../assets/Hand.svg"
                alt="users" />
            <div class="individual-format__text-container">
                <div class="individual-format__name">{{ translates[langUrl].type_2_title[lang] }}</div>
                <div class="individual-format__description">{{ translates[langUrl].type_2_description[lang] }}</div>
            </div>
        </div>
        <div class="individual-format__container">
            <img
                class="individual-format__image"
                src="../../../assets/Handshake.svg"
                alt="users" />
            <div class="individual-format__text-container">
                <div class="individual-format__name">{{ translates[langUrl].type_3_title[lang] }}</div>
                <div class="individual-format__description">{{ translates[langUrl].type_3_description[lang] }}</div>
            </div>
        </div>
        <div class="individual-format__container">
            <img
                class="individual-format__image"
                src="../../../assets/ShieldCheck.svg"
                alt="users" />
            <div class="individual-format__text-container">
                <div class="individual-format__name">{{ translates[langUrl].type_4_title[lang] }}</div>
                <div class="individual-format__description">{{ translates[langUrl].type_4_description[lang] }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'IndividualFormat',
        data: () => ({
            langUrl: 'individualFormat'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        }
    }
</script>

<style lang="scss">
.individual-format {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #3A4256;
    @media all and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 768px;
        font-size: 16px;
    }
    &__container {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
    }
    &__image {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    &__text-container {
        flex-direction: column;
        @media all and (min-width: 768px) {
            width: 350px;
        }
    }
    &__name {
        font-weight: 600;
        @media all and (min-width: 768px) {
            font-size: 20px;
        }
    }
    &__description {
        margin-top: 3px;
    }
}
</style>
