<template>
    <!-- <div style="width: 100%; height: 25vw; overflow:hidden"> -->
    <div class="photo-gallery">
        <div 
            v-for="(image, index) in photos.slice(0,18)"
            :key="index"
            class="photo-gallery__image"
            :style="`background-image: url(${ imageSrc(image.picture) })`"
        ></div>
    </div>
    
        
    <!-- <img
            v-for="(image, index) in user.images"
            :key="index"
            class="photo-gallery__image"
            :src="imageSrc(image)"
            width="100%" /> -->
    <!-- </div> -->
</template>

<script>
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers'

    export default {
        name: 'WaterfallGrid',
        components: {
            
        },
        props: {
            photos: {
                type: Array,
                default: null
            },
        },
        data: () => ({
            imgsArr: [],
            imageSrc
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        methods: {
            getImg() {
                this.imgsArr = this.user.images
            }
        }
    }
</script>

<style lang="scss">
    .photo-gallery {
        width: 100vw;
        height: 60vw;
        display: grid;
        grid-gap: 7px;
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        grid-auto-flow: dense;
        overflow: hidden;
        @media all and(max-width: 768px) {
            display: flex;
            flex-direction: row;
            overflow: scroll;
            height: 200px;
        }
        &__image {
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            grid-row: span 1;
            grid-column: span 1;
            @media all and(max-width: 768px) {
                min-width: 300px;
                height: 200px;
                &:first-of-type {
                    margin-left: 20px;
                }
                &:last-of-type {
                    margin-right: 20px;
                }
            }

            &:nth-of-type(1),
            &:nth-of-type(4) {
                grid-row: span 2;
                grid-column: span 3;
            } 
            &:nth-of-type(3),
            &:nth-of-type(6),
            &:nth-of-type(8) {
                grid-column: span 2;
            }
            &:nth-of-type(2),
            &:nth-of-type(5),
            &:nth-of-type(7) {
                grid-column: span 3;
            }
            &:nth-of-type(10),
            &:nth-of-type(14) {
                grid-row: span 2;
                grid-column: span 3;
            } 
            &:nth-of-type(13),
            &:nth-of-type(16),
            &:nth-of-type(18) {
                grid-column: span 2;
            }
            &:nth-of-type(12),
            &:nth-of-type(15),
            &:nth-of-type(17) {
                grid-column: span 3;
            }
        }
        
    }

</style>
