<template>
    <div class="right-info-block" v-if="translates && translates[langUrl]">
        <DateSelect
            :items="dateSelectItems"
            item-text="label"
            item-value="value"
            :fieldTitle="translates[langUrl].fieldTitle_date[lang]"
            :value="0"
            @change="setActiveArrival"
        />
        <div v-if="!bloggersPage" class="right-info-block__offers">
            <div class="right-info-block__offers__offer right-info-block__offers__discount">
                <img src="../../../assets/Discount.svg" class="right-info-block__offers__picture" />
                <div class="right-info-block__offers__text">{{ translates[langUrl].offers_first[lang] }}</div>
            </div>
            <div class="right-info-block__offers__offer right-info-block__offers__discount">
                <img src="../../../assets/Cards.svg" class="right-info-block__offers__picture" />
                <div class="right-info-block__offers__text" v-html="translates[langUrl].offers_second[lang]"></div>
            </div>
        </div>
        <div v-if="bloggersPage" class="right-info-block__offers">
            <div class="right-info-block__offers__offer right-info-block__offers__discount">
                <img src="../../../assets/Discount.svg" class="right-info-block__offers__picture" />
                <div class="right-info-block__offers__text">{{ translates[langUrl].offers_bloggersPage_first[lang] }}</div>
            </div>
            <div class="right-info-block__offers__offer right-info-block__offers__discount">
                <img src="../../../assets/Cards.svg" class="right-info-block__offers__picture" />
                <div class="right-info-block__offers__text" v-html="translates[langUrl].offers_bloggersPage_second[lang]"></div>
            </div>
        </div>
        <div v-if="dateSelectItems.length !== 0 && !bloggersPage" class="right-info-block__cost-and-duration">
            <div class="right-info-block__cost-and-duration__cost"><span v-html="priceFormated(priceForDay, activeArrival.currency)"></span> {{ translates[langUrl].cost[lang] }}</div>
            <div>{{ trip.programs.length }} {{ translates[langUrl].duration[lang] }}</div>
        </div>
        <div v-if="activeArrival.empty && activeArrival.places" class="right-info-block__places">
            <div class="right-info-block__places__text"> {{ activeArrival.empty }} {{ translates[langUrl].outOf[lang] }} {{ activeArrival.places }} {{ translates[langUrl].emptyPlaces[lang] }}</div>
            <div class="right-info-block__places__background" :style="`width: calc(${occupiedPlaces} * 100%)`">
                <div class="right-info-block__places__text right-info-block__places__text__white"> {{ activeArrival.empty }} {{ translates[langUrl].outOf[lang] }} {{ activeArrival.places }} {{ translates[langUrl].emptyPlaces[lang] }}</div>
            </div>
        </div>
        <div v-if="dateSelectItems.length !== 0 && !bloggersPage" class="right-info-block__prices">
            <div class="right-info-block__prices__sale">
                <template v-if="activeArrival.discount && activeArrival.discount !== 0">
                    <div class="right-info-block__prices__default-price"><span v-html="priceFormated(cost(), activeArrival.currency)"></span></div>
                    <div class="right-info-block__prices__percent"> {{ activeArrival.discount }}%</div>
                </template>
            </div>
            <div class="right-info-block__prices__final-price"><span v-html="priceFormated(priceWithSale, activeArrival.currency)"></span></div>
        </div>
        <div v-if="dateSelectItems.length !== 0 && !bloggersPage" class="right-info-block__booking">
            <div class="right-info-block__booking__info">{{ translates[langUrl].prepayment[lang] }} <span v-html="priceFormated(prepayment(), activeArrival.currency)"></span></div>
            <div class="right-info-block__booking__count-and-button">
                <div class="right-info-block__booking__count-and-button__count">
                    <div class="right-info-block__booking__count-and-button__count__text">{{ translates[langUrl].travelersCounter[lang] }}</div>
                    <BaseCounter
                        class="right-info-block__booking__count-and-button__count__counter"
                        :value="travelersNumber"
                        :max-value="activeArrival.empty"
                        @change="updateTraveler"
                    />
                </div>
                <BaseButton
                    v-if="checkArrival(activeArrival)"
                    style="height: 45px; width: 100%;"
                    @click="payBooking(activeArrival)">{{ translates[langUrl].button_pay[lang] }}</BaseButton>
                <BaseButton
                    v-else
                    style="height: 45px; width: 100%;"
                    @click="bookArrival(activeArrival)">{{ translates[langUrl].button_booking[lang] }}</BaseButton>
            </div>
            <div class="right-info-block__booking__cancel">{{ translates[langUrl].cancelBooking[lang] }}</div>
        </div>
        <div v-if="dateSelectItems.length === 0 && !bloggersPage" class="right-info-block__no_active-tours">
            <div class="right-info-block__no_active-tours__text">{{ translates[langUrl].arrivalsEmpty[lang] }}</div>
            <BaseButton style="height: 45px; width: 100%; margin-bottom: 10px" @click="downloadProgram">{{ translates[langUrl].button_tourProgram[lang] }}</BaseButton>
        </div>
        <div v-if="bloggersPage" class="right-info-block__no_active-tours">
            <div class="right-info-block__no_active-tours__text">{{ translates[langUrl].arrivalsEmpty_bloggersPage[lang] }}</div>
            <BaseButton style="height: 45px; width: 100%; margin-bottom: 10px" @click="participation">{{ translates[langUrl].button_requestParticipation[lang] }}</BaseButton>
        </div>
        <div v-if="isMobile" class="right-info-block__organizer">
            <template v-if="trip.organizer && trip.organizer.firstname && !bloggersPage">
                <div class="right-info-block__organizer__text">
                    <div class="right-info-block__organizer__text__name"> {{ trip.organizer.firstname }}, {{ translates[langUrl].organizer[lang] }}</div>
                    <!-- <div class="right-info-block__organizer__text__raiting">4,5 <img src="../../../assets/Stars.jpg" /></div> -->
                </div>
                <div class="right-info-block__organizer__avatar" :style="`background-image: url(${ imageSrc(trip.organizer.avatar) })`"></div>
            </template>
            <template v-else-if="trip.user && !bloggersPage">
                <div class="right-info-block__organizer__text">
                    <div class="right-info-block__organizer__text__name"> {{ trip.user.firstname }}, {{ translates[langUrl].organizer[lang] }}</div>
                    <!-- <div class="right-info-block__organizer__text__raiting">4,5 <img src="../../../assets/Stars.jpg" /></div> -->
                </div>
                <div class="right-info-block__organizer__avatar" :style="`background-image: url(${ imageSrc(trip.user.avatar) })`"></div>
            </template>
        </div>
        <BaseButton
            v-if="user && trip && trip.user && user._id !== trip.user._id && !bloggersPage"
            class="button-fill"
            style="width: 100%"
            @click="writeToExpert">{{ translates[langUrl].button_writeToExpert[lang] }}</BaseButton>
    </div>
</template>

<script>
    import DateSelect from './DateSelect.vue'
    import BaseButton from '../BaseButton.vue'
    import BaseCounter from '../BaseCounter.vue'

    import { imageSrc, priceFormated, isMobile } from '@/helpers';
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS, BOOKING_TYPE } from '@/vars';
    import moment from 'moment'

    export default {
        name: 'BookingBlock',
        components: {
            DateSelect,
            BaseButton,
            BaseCounter
        },
        props: {
            tripId: {
                type: String,
                default: ''
            },
            prepaymentPercent: {
                type: Number,
                default: 15
            },
            bloggersPage: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            dates: [],
            activeArrivalIndex: 0,
            travelersNumber: 1,
            BOOKING_STATUS,
            priceFormated,
            lastMove: null,
            isMobile: false,
            langUrl: 'bookingBlock'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                }
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            filteredArrivals() {
                const now = new Date();

                let arrivals = [];
                if(this.trip.bloggerTrips && this.trip.bloggerTrips.length) {
                    arrivals = this.trip.bloggerTrips.map(item => ({
                        _id: item._id,
                        type: BOOKING_TYPE.BLOGGER_TRIP,
                        price: item.price + item.bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        discount: 0,
                        discountBefore: 0,
                        places: item.bloggerPlaces,
                        empty: item.empty || item.bloggerPlaces,
                        start: item.start,
                        finish: item.finish,
                    }));
                } else if(this.trip.arrivals) {
                    arrivals = this.trip.arrivals.map(item => ({
                        _id: item._id,
                        type: BOOKING_TYPE.ARRIVAL,
                        price: item.price,
                        currency: item.currency,
                        discount: item.discount,
                        discountBefore: item.discountBefore,
                        places: item.places,
                        empty: item.empty || item.places,
                        start: item.start,
                        finish: item.finish,
                    }));
                }

                if(arrivals) {
                    arrivals = arrivals.filter(arrival => {
                        return new Date(arrival.start).getTime() > now.getTime();
                    });
                    arrivals = arrivals.sort((arrivalA, arrivalB) => {
                        return new Date(arrivalA.start).getTime() - new Date(arrivalB.start).getTime();
                    });
                    if(this.selectedMonth) {
                        arrivals = arrivals.filter(arrival => {
                            return moment(arrival.start).format('LL') === this.selectedMonth;
                        });
                    }
                }
                return arrivals || [];
            },
            dateSelectItems() {
                return this.filteredArrivals ? this.filteredArrivals.map((item, index) => ({
                    label: moment(item.start).format('DD.MM.YY') + ' - ' + moment(item.finish).format('DD.MM.YY'),
                    value: index
                })) : [];
            },
            occupiedPlaces() {
                return ((this.activeArrival.places - this.activeArrival.empty) / this.activeArrival.places)
            },
            activeArrival() {
                return this.filteredArrivals[this.activeArrivalIndex] || {};
            },
            priceForDay() {
                return Math.round(this.priceWithSale / this.trip.programs.length)
            },
            priceWithSale() {
                if (this.activeArrival && this.activeArrival.discount){
                    return Math.round(this.cost() * ((100 - this.activeArrival.discount) / 100))
                }
                return this.activeArrival ? + this.cost() : 0
            },
        // prepayment() {
        //     return (this.prepaymentPercent * this.cost)
        // }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted(){
            // store.dispatch( 'bookings/fetch', {} );
        },
        methods: {
            onResize() {
                this.isMobile = !isMobile();
            },
            setActiveArrival(index) {
                this.activeArrivalIndex = index;
            },
            updateTraveler(value) {
                this.travelersNumber = value;
            },
            cost() {
                return (this.travelersNumber * this.activeArrival.price);
            },
            prepayment() {
                return((this.prepaymentPercent / 100) * this.cost())
            },
            downloadProgram() {
                this.$emit('downloadProgram')
            },
            participation() {
                this.$emit('participation')
            },
            async writeToExpert() {
                if(this.user._id) {
                    this.$root.$emit('tripChatOpen');
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.$root.$emit('tripChatOpen');
                    });
                }
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            checkArrival(arrival) {
                for(const booking of this.bookings) {
                    if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                        return true;
                    }
                }
                return false;
            },
            async createBooking(arrival) {
                for(const booking of this.bookings) {
                    if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                        await store.dispatch('bookings/delete', { id: booking._id });
                    }
                }
                store.commit('bookings/CLEAR_ENTITY');
                store.commit('bookings/SET_ENTITY', {
                    type: arrival.type,
                    trip: this.tripId,
                    arrival: arrival._id,
                    start: arrival.start,
                    finish: arrival.finish,
                    places: this.travelersNumber,
                    price: arrival.price,
                    discount: arrival.discount,
                    currency: arrival.currency,
                });
                const booking = await store.dispatch('bookings/save');
                this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
            },
            async bookArrival(arrival) {
                if(this.user._id) {
                    await this.createBooking(arrival);
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await this.createBooking(arrival);
                    });
                }
                
            },
            async deleteBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            await store.dispatch('bookings/delete', { id: booking._id });
                        }
                    }
                    await store.dispatch('bookings/fetch', {});
                    await store.dispatch('trips/get', { id: this.tripId });
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        for(const booking of this.bookings) {
                            if(booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                                await store.dispatch('bookings/delete', { id: booking._id });
                            }
                        }
                        await store.dispatch('bookings/fetch', {});
                        await store.dispatch('trips/get', { id: this.tripId });
                    });
                }
            },
            async payBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                        }
                    }
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        for(const booking of this.bookings) {
                            if(booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                                this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                            }
                        }
                    });
                }
            },
        }
    }

</script>

<style lang="scss">
.right-info-block {
    position: relative;
    max-width: 420px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    z-index: 5;
    &__offers {
        display: flex;
        flex-direction: row;
        padding: 16px 0;
        border-bottom: 1px solid #EBEBEB;
        &__offer {
            display: flex;
            flex-direction: row;
        }
        &__text {
            margin-left: 12px;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #A9AEB2;
        }
        &__discount{
            min-width: 150px;
        }
    }
    &__cost-and-duration {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #000000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;
    }
    &__places {
        position: relative;
        width: 100%;
        height: 40px;
        background: #EBEBEB;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 16px;
        &__background {
            position: absolute;
            height: 100%;
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
            overflow: hidden;
        }
        &__text {
            position: absolute;
            width: 320px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &__white {
                color: #FFFFFF;
            }
            @media all and(max-width: 768px) {
                width: 320px;
            }
            @media all and(max-width: 1280px) {
                width: 300px;
            }
        }
    }
    &__prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6px 16px;
        border-bottom: 1px solid #EBEBEB;
        @media all and (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
        }
        &__default-price{
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            text-decoration-line: line-through;
            color: #9FA5AD;
            flex: none;
        }
        &__sale {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @media all and (max-width: 768px) {
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
        &__percent {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 22px;
            background: #3A4256;
            border-radius: 20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            margin-left: 6px;
        }
        &__final-price {
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: -0.02em;
            color: #3A4256;
            white-space: nowrap;
        }
    }
    &__booking {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #EBEBEB;
        &__info {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #000000;
            margin-bottom: 16px;
        }
        &__count-and-button {
            background: #FFFFFF;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            width: 100%;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &__count {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px;
                &__text {
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
            }

        }
        &__cancel {
            margin-top: 10px;
            padding: 0 2px;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #A9AEB2;
        }
    }
    &__no_active-tours {
        padding: 10px 0;
        border-bottom: 1px solid #EBEBEB;
        &__text {
            font-weight: bold;
            font-size: 26px;
            line-height: 30px;
            letter-spacing: -0.02em;
            color: #3A4256;
            margin: 5px 0 15px;
        }
        &__download-link {
        position: absolute;
        width: 285px;
        height: 45px;
    }
    }
    &__organizer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0;
        &__text {
            color: #3A4256;
            margin-left: 10px;
            &__name {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;
            }
            &__raiting {
                font-size: 14px;
                line-height: 15px;
                letter-spacing: -0.02em;
                vertical-align: center;
            }

        }
        &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            // background: #EBEBEB;
        }
    }
}
</style>