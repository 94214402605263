<template>
    <div class="review" v-if="translates && translates[langUrl]">
        <div v-if="!formReverse">
            <h1 class="review__page-name">{{ translates[langUrl].pageTitle_newReview[lang] }}</h1>
            <div class="review__close" @click="close"></div>
            <ValidationObserver ref="validator_1" slim>
                <div class="review__raiting-line main-raiting">
                    <div class="review__raiting__key">{{ translates[langUrl].rateTheTour[lang] }}</div>
                    <v-rating
                        v-model="review.rating.overall"
                        background-color="grey"
                        color="warning"
                        hover
                        length="5"
                        size="30"
                    />
                </div>
                <!-- <p>Рэйтинг</p>
                <v-row>
                    <v-col>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <v-select
                                v-model="review.rating.overall"
                                label="Общий"
                                :items="[0,1,2,3,4,5]"
                                outlined
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>                    
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.route"
                            :label="translates[langUrl].rating_rout[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.food"
                            :label="translates[langUrl].rating_food[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.accommodation"
                            Жlabel="translates[langUrl].rating_accommodation[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.transport"
                            :label="translates[langUrl].rating_transport[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                </v-row> -->
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-textarea
                        v-model="review.text"
                        :label="translates[langUrl].fieldName_review[lang]"
                        auto-grow
                        outlined
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <div class="review__additional-raiting__container">
                    <div class="review__additional-raiting">
                        <div class="review__additional-raiting__raiting__key">
                            {{ translates[langUrl].rating_transport[lang] }}
                        </div>
                        <div class="review__raiting-line other">
                            <v-rating
                                v-model="review.rating.transport"
                                background-color="grey"
                                color="warning"
                                hover
                                length="5"
                                size="30"
                            />
                            <div class="review__additional-raiting__delete">{{ translates[langUrl].button_delete[lang] }}</div>
                        </div>
                    </div>
                    <div class="review__additional-raiting">
                        <div class="review__additional-raiting__raiting__key">
                            {{ translates[langUrl].rating_rout[lang] }}
                        </div>
                        <div class="review__raiting-line other">
                            <v-rating
                                v-model="review.rating.route"
                                background-color="grey"
                                color="warning"
                                hover
                                length="5"
                                size="30"
                            />
                            <div class="review__additional-raiting__delete">{{ translates[langUrl].button_delete[lang] }}</div>
                        </div>
                    </div>
                    <div class="review__additional-raiting">
                        <div class="review__additional-raiting__raiting__key">
                            {{ translates[langUrl].rating_food[lang] }}
                        </div>
                        <div class="review__raiting-line other">
                            <v-rating
                                v-model="review.rating.food"
                                background-color="grey"
                                color="warning"
                                hover
                                length="5"
                                size="30"
                            />
                            <div class="review__additional-raiting__delete">{{ translates[langUrl].button_delete[lang] }}</div>
                        </div>
                    </div>
                </div>
                <BaseButton
                    class="button-fill review__save"
                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                <div class="review__personal-info">
                    {{ translates[langUrl].userAgreement[lang] }}
                </div>
            </ValidationObserver>
        </div>
        <div v-if="formReverse">
            {{ translates[langUrl].thanks[lang] }}
            <div class="review__close" @click="close"></div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from 'moment';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseTextArea from '../../components/common/BaseTextArea.vue'

    export default {
        name: 'ReviewForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextArea
        },
        data: () => ({
            review: {
                rating: {
                    overall: 0,
                    route: 0,
                    food: 0,
                    accommodation: 0,
                    transport: 0
                },
            },
            formReverse: false,
            langUrl: 'reviewForm'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('trips/getByCode', { code: this.$route.params.code });
        },
        methods: {
            async save() {
                store.commit('reviews/SET_ENTITY', {
                    ...this.review,
                    ...{
                        date: moment().format(),
                        user: this.user._id,
                        trip: this.trip._id
                    }
                });
                await store.dispatch('reviews/save');
                await this.$router.push({ name: 'trip', params: { code: this.$route.params.code } });
                this.formReverse = true;
            },
            close() {
                this.$emit('close')
                this.formReverse = false;
                this.review = {
                    rating: {
                        overall: 0,
                        route: 0,
                        food: 0,
                        accommodation: 0,
                        transport: 0
                    },
                    text: '',
                }
            }
        }
    }
</script>

<style lang="scss">
    .review {
        position: relative;
        max-height: 700px;
        width: 100%;
        padding: 50px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        &__page-name {
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 30px;
        }
        &__raiting{
            &-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                &.main-raiting {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #E5E6E9;
                    margin-bottom: 20px;
                }
                &.other {
                    justify-content: space-between;
                }
            }
            &__key {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #3A4256;
            }
            
        }
        &__additional-raiting {
            padding: 20px;
            border: 1px solid #E5E6E9;
            border-radius: 10px;
            min-width: 440px;
            margin: 10px 20px 20px 0;
            // margin-right: 20px;
            &__container {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                // &::-webkit-scrollbar {
                //     height: 0;
                // }
            }
            &__raiting__key {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #3A4256;
                margin-bottom: 20px;
                max-width: 330px;
            }
            &__delete {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #3A4256;
                cursor: pointer;
            }
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
    }
</style>
