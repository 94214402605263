<template>
    <div v-if="translates && translates[langUrl]">
        <DesktopGallery
            v-if="isDesktop"
            :options="{ rewind: true, type: 'loop', perPage: placesSplideCounter, perMove: 1, gap: '20px' }"
            :items="trip.mainPlaces"
            :title="translates[langUrl].blockName_mainPlaces[lang]"
            :number-of-images="placesSplideCounter"
        />
        <TripGallery
            v-if="isDesktop && !isDesktop"
            :items="trip.mainPlaces"
            :title="translates[langUrl].blockName_mainPlaces[lang]"
            class="large" />
        <yandexMap
            v-if="isDesktop && trip.direction"
            ref="ymap"
            class="detail-main-block__yandexmap"
            :controls="['zoomControl']"
            :coords="centerCoordinates"
            zoom="6"
        >
            <template v-for="(direct, i) in trip.direction">
                <ymapMarker 
                    :key="i"
                    :marker-id="i"
                    marker-type="Placemark"
                    :coords="direct.coordinates"
                />
            </template>
            <ymapMarker
                marker-id="1"
                marker-type="Polyline"
                :coords="polyline"
            />
        </yandexMap>
        <Benefits
            v-if="trip.benefits && trip.benefits[0] && trip.benefits[0].title && trip.benefits[0].title !== ''"
            :title="translates[langUrl].blockName_highlights[lang]"
            :items="trip.benefits"
        />
        <div v-if="isDesktop" class="detail-main-block__block-name">{{ translates[langUrl].blockName_individualFormat[lang] }}</div>
        <IndividualFormat v-if="isDesktop" />
        <div style="width: 100%; display: flex; justify: center">
            <BaseButton
                v-if="bloggersPage"
                style="width: 326px; margin: 40px auto 0"
                @click="participation"
            >{{ translates[langUrl].button_choose[lang] }}</BaseButton>
            <BaseButton
                v-else
                style="width: 326px; margin: 40px auto 0"
                @click="individual"
            >{{ individualText? individualText : `${ translates[langUrl].defaultIndividualText[lang] }` }}</BaseButton>
        </div>
        
        <template v-if="trip.programs && trip.programs.length > 1">
            <div ref="program" class="detail-main-block__block-name">{{ translates[langUrl].blockName_tourProgram[lang] }}</div>
            <TripProgram :items="programsFormatted" :isDesktop="isDesktop" />
        </template>
        <div class="detail-main-block__presentation">
            <div class="detail-main-block__presentation__text">
                <div class="detail-main-block__presentation__title">{{ translates[langUrl].presentation_title[lang] }}</div>
                <div class="detail-main-block__presentation__description">{{ translates[langUrl].presentation_description[lang] }}</div>
            </div>
            <BaseButton
                v-if="bloggersPage"
                class="button-fill detail-main-block__presentation__button"
                @click="participation"
            >{{ translates[langUrl].button_participation[lang] }}
            </BaseButton>
            <BaseButton
                v-else
                class="button-fill detail-main-block__presentation__button"
                @click="downloadProgram()"
            >{{ translates[langUrl].button_downloadPresentation[lang] }}<img src="../../../assets/DownloadWhite.svg" style="width: 20px; heigth: 20px; margin-left: 5px" />
            </BaseButton>
        </div>
        <div v-if="!bloggersPage" ref="organizer" class="detail-main-block__block-name">{{ translates[langUrl].blockName_expert[lang] }}</div>
        <Organizer v-if="!bloggersPage" :user="trip.organizer && trip.organizer.firstname ? trip.organizer : trip.user" />
        <h3 v-if="trip.included && trip.included.length > 0 && trip.included[0].title !== '' && trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== ''" 
            class="detail-main-block__block-name"
            >{{ translates[langUrl].blockName_advantages[lang] }}</h3>
        <TripAdvantages 
            v-if="trip.included && trip.included.length > 0 && trip.included[0].title !== ''" 
            :items="trip.included" 
            :openAllItems="true"
            />
        <TripAdvantages
            v-if="trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== ''"
            class="notIcluded"
            :items="trip.notIncluded" />
        <DesktopGallery
            v-if="isDesktop"
            ref="accommodation"
            :options="{ rewind: true, type: 'loop', fixedWidth : '650px', fixedHeight: '350px', perMove: 1, gap: '20px' }"
            :items="trip.accommodations"
            :title="translates[langUrl].blockName_accommodations[lang]"
            number-of-images="1"
            :description="trip.accommodationsDescription"
            description-lenght="250"
            class="large" />
        <TripGallery
            v-if="!isDesktop"
            :items="trip.accommodations"
            :title="translates[langUrl].blockName_accommodations[lang]"
            :description="trip.accommodationsDescription"
            description-lenght="80"
            class="raiting" />
        <DesktopGallery
            v-if="isDesktop"
            :options="{ rewind: true, type: 'loop', fixedWidth : '650px', fixedHeight: '350px', perMove: 1, gap: '20px' }" 
            :items="trip.activities"
            :title="translates[langUrl].blockName_activities[lang]"
            number-of-images="1"
            :description="trip.activitiesDescription"
            description-lenght="200"
            class="large" 
        />
        <TripGallery
            v-if="!isDesktop"
            :items="trip.activities"
            :title="translates[langUrl].blockName_activities[lang]"
            :description="trip.activitiesDescription"
            description-lenght="80"
        />
        <DesktopGallery
            v-if="isDesktop"
            :options="{ rewind: true, type: 'loop', fixedWidth : '650px', fixedHeight: '350px', perMove: 1, gap: '20px' }"
            :items="trip.transports"
            :title="translates[langUrl].blockName_transports[lang]"
            number-of-images="1"
            :description="trip.transportsDescription"
            description-lenght="200"
            class="large"
            :transports="trip.transportsTypes"
        />
        <TripGallery
            v-if="!isDesktop"
            :items="trip.transports"
            :title="translates[langUrl].blockName_transports[lang]"
            :description="trip.transportsDescription"
            description-lenght="80"
        />
        <v-dialog
            v-model="popupConsultation"
            max-width="600px"
        >
            <PopupConsultation
                @close="closePopupConsultation"
            />
        </v-dialog>
        <v-dialog
            v-model="popupParticipation"
            max-width="600px"
        >
            <PopupParticipation
                @close="closePopupParticipation"
            />
        </v-dialog>
        <v-dialog
            v-model="popupDownload"
            max-width="600px"
        >
            <PopupDownload
                @close="closePopupDownload"
                @download="download"
            />
        </v-dialog>
        <v-dialog
            v-model="PopupNoPresentation"
            max-width="600px"
        >
            <PopupNoPresentation 
                :name="user.firstname"
                @close="closePopupNoPresentation"
                @writeToExpert="writeToExpert"
            />
        </v-dialog>
        <a
            ref="dowloadProgram"
            :href="downloadFile(trip.PDF)"
            target="_blank"></a>
    </div>
</template>

<script>
    import BaseButton from '../BaseButton.vue';
    import TripProgram from './Program.vue';
    import TripAdvantages from './Advantages.vue';
    import TripGallery from './Gallery.vue';
    import DesktopGallery from './DesktopGallery.vue';
    import Organizer from './Organizer.vue';
    import IndividualFormat from './IndividualFormat.vue';
    import Benefits from './Benefits.vue';
    import PopupConsultation from '../PopupConsultation.vue';
    import PopupDownload from '../PopupDownload.vue';
    import PopupNoPresentation from '../PopupNoPresentation.vue';
    import PopupParticipation from '../PopupParticipation.vue';

    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc, SplideSlideCounter, isMobile } from '@/helpers';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    

export default {
    name: 'DetailMainBlock',
    components: {
        BaseButton,
        TripProgram,
        Organizer,
        IndividualFormat,
        DesktopGallery,
        TripAdvantages,
        Benefits,
        yandexMap,
        ymapMarker,
        TripGallery,
        PopupConsultation,
        PopupDownload,
        PopupNoPresentation,
        PopupParticipation
    },
    data: () => ({
        imageSrc,
        isDesktop: false,
        hide: true,
        lastMove: null,
        placesSplideCounter: 1,
        popupConsultation: false,
        popupParticipation: false,
        popupDownload: false,
        PopupNoPresentation: false,
        feedback: {
            trip: null,
            name: '',
            phone: '',
            email: '',
            comment: '',
            link: '',
        },
        bloggersPage: false,
        individualText: '',
        langUrl: '/trip/name',
    }),
    created () {
        this.isDesktop = !isMobile();
        this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
    },
    computed: {
        ...mapState('settings', ['lang']),
        ...mapState('trips', {
            trips: state => state.entities
        }),
        ...mapState('trips', {
            trip: state => state.entity
        }),
        ...mapState('auth', ['user']),
        ...mapState('reviews', {
            reviews: state => state.entities
        }),
        ...mapState('translates', {
            translates: state => state.entities
        }),
        photos() {
            return this.trip.photos? this.trip.photos.map(item => item.picture) : [] || []
        },
        filteredtrips() {
            return this.trips.filter(item => {
                if (this.trip.code.toString() === item.code.toString()){
                    return false;
                }
                return true;
            });
        },
        programsFormatted() {
            return this.trip && this.trip.programs ? this.trip.programs.map((item, index) => {
                if(this.lang === 'ru') {
                    item.title = `День ${ index + 1 }. ${ item.title }`;
                    return item;
                } else {
                    item.title = `Day ${ index + 1 }. ${ item.title }`;
                    return item;
                }
                
            }) : []
        },
        options () {
            return {
                offset: 100
            }
        },
        polyline() {
            return this.trip.direction ? this.trip.direction.map(item => item.coordinates) : [];
        },
        centerCoordinates() {
            return this.trip.direction && this.trip.direction.length ? [
                this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                    return sum + +item.coordinates[0]
                }, 0) / this.trip.direction.length : 55.749451,
                this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                    return sum + +item.coordinates[1]
                }, 0) / this.trip.direction.length : 37.542824,
            ] : [];
        },
    },
    methods: {
        async writeToExpert() {
            this.$emit('writeToExpert')
        },
        async downloadProgram() {
            if(this.user._id) {
                this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_presentation[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ this.user.phone } ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                if(this.trip.PDF) {
                    this.openPopupDownload()
                } else {
                    this.openPopupNoPresentation()
                }
            } else {
                this.authorization()
                this.$root.$once('authorized', async () => {
                    this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_presentation[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                    if(this.trip.PDF) {
                        this.openPopupDownload()
                    } else {
                        this.openPopupNoPresentation()
                    }
                });
            }
        },
        downloadFile(file) {
            return file ? `${ process.env.VUE_APP_STATIC_URL }/${ file.replace(/\\/g, '/') }` : ''
        },
        authorization() {
            if(!this.user._id) {
                this.$root.$emit('popupLoginShow');
            }
        },
        onResize() {
            this.isDesktop = !isMobile();
        },
        async sendFeedback(comment) {
            this.feedback = {
                trip: this.trip._id,
                name: this.user.firstname,
                phone: this.user.phone,
                email: this.user.email,
                comment: comment,
                link: document.location.href
            };
            await store.commit('feedbacks/SET_ENTITY', this.feedback);
            await store.dispatch('feedbacks/save');
            await store.commit('feedbacks/CLEAR_ENTITY');
        },
        async consultation() {
            if (this.user._id) {
            this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_consultation[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
            this.openPopupConsultation();
            } else {
                this.authorization()
                this.$root.$once('authorized', async () => {
                    this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_consultation[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                    this.openPopupConsultation();
                });
            }
        },
        async participation() {
            if (this.user._id) {
            this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_participation[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
            this.openPopupParticipation();
            } else {
                this.authorization()
                this.$root.$once('authorized', async () => {
                    this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_participation[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                    this.openPopupParticipation();
                });
            }
        },
        async individual() {
            if (this.user._id) {
            this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_individual[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
            this.individualText = `${ this.translates[this.langUrl].requestIndividualAccepted[this.lang]}`
            } else {
                this.authorization()
                this.$root.$once('authorized', async () => {
                this.sendFeedback(`${ this.translates[this.langUrl].userActionsNotification_part_1[this.lang]} ${ this.user.phone } ${ this.translates[this.langUrl].userActionsNotification_part_2_individual[this.lang]} "${ this.trip.name }", ${ this.translates[this.langUrl].userActionsNotification_part_3[this.lang]} ${ process.env.VUE_APP_CURRENT_URL }/trip/${ this.trip.code }`)
                this.individualText = `${ this.translates[this.langUrl].requestIndividualAccepted[this.lang]}`
                });
            }
        },
        async download() {
            this.$refs.dowloadProgram.click();
        },
        openPopupConsultation() {
            this.popupConsultation = true;
        },
        closePopupConsultation() {
            this.popupConsultation = false;
        },
        openPopupParticipation() {
            this.popupParticipation = true;
        },
        closePopupParticipation() {
            this.popupParticipation = false;
        },
        openPopupDownload() {
            this.popupDownload = true;
        },
        closePopupDownload() {
            this.popupDownload = false;
        },
        openPopupNoPresentation() {
            this.PopupNoPresentation = true;
        },
        closePopupNoPresentation() {
            this.PopupNoPresentation = false;
        }
    }
}
</script>

<style lang="scss">
.detail-main-block {
    &__yandexmap {
        width: 100%;
        height: 400px;
        margin-top: 38px;
        border-radius: 12px;
        overflow: hidden;
    }
    &__presentation {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 112px;
        padding: 30px 30px 30px 25px;
        background: #F6F7F9;
        border-radius: 12px;
        justify-content: space-between;
        margin-top: 70px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            min-height: 220px;
            background: #F6F7F9;
            border-radius: 12px;
            padding: 16px 24px;
            margin: 40px 0;
        }
        &__text {
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
        &__title {
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            @media all and (max-width: 768px) {
                font-weight: 600;
                font-size: 22px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 12px;
            }
        }
        &__description {
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            @media all and (max-width: 768px) {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 24px;
            }
        }
        &__button {
            width: 254px;
            height: 52px;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
        margin-top: 70px;
    }
}
</style>
