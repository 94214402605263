<template>
    <div v-if="translates && translates[langUrl] && reviews.items && reviews.items.length > 0 && reviews.items[0].text !== ''" class="reviews">
        <!-- <div class="reviews__bar" @click="calculateRating('overall')">
            <div class="reviews__bar__item reviews__bar__item__first">
                <div class="reviews__bar__item__category">
                    <img src="../../../assets/StarsRating.svg" class="reviews__bar__item__category__icon" />
                    {{ translates[langUrl].raiting_general[lang] }}
                </div>
                <div class="reviews__bar__item__value">
                    4,5
                    <img src="../../../assets/StarRating.svg" class="reviews__bar__item__value__star" />
                </div>
            </div>
            <div class="reviews__bar__item">
                <div class="reviews__bar__item__category">
                    <img src="../../../assets/MapRating.svg" class="reviews__bar__item__category__icon" />
                    {{ translates[langUrl].raiting_rout[lang] }}
                </div>
                <div class="reviews__bar__item__value">
                    5,0
                    <img src="../../../assets/StarRating.svg" class="reviews__bar__item__value__star" />
                </div>
            </div>
            <div class="reviews__bar__item">
                <div class="reviews__bar__item__category">
                    <img src="../../../assets/CarRating.svg" class="reviews__bar__item__category__icon" />
                    {{ translates[langUrl].raiting_transport[lang] }}
                </div>
                <div class="reviews__bar__item__value">
                    4,3
                    <img src="../../../assets/StarRating.svg" class="reviews__bar__item__value__star" />
                </div>
            </div>
            <div class="reviews__bar__item  reviews__bar__item__last">
                <div class="reviews__bar__item__category">
                    <img src="../../../assets/ForkKnifeRating.svg" class="reviews__bar__item__category__icon" />
                    {{ translates[langUrl].raiting_food[lang] }}
                </div>
                <div class="reviews__bar__item__value">
                    4,5
                    <img src="../../../assets/StarRating.svg" class="reviews__bar__item__value__star" />
                </div>
            </div>
        </div> -->
        <BaseButton style="width: 100%;" @click="openReview">
            <div style="font-size: 16px; line-height: 140%; font-weight: 600; margin-top: 3px">{{ translates[langUrl].button_newReview[lang] }}</div> 
            <div style="font-size: 25px; font-weight: 300; margin-left: 7px;">+</div></BaseButton>
        <v-dialog 
            v-model="shownReview"
            max-width="600px" 
        >
            <ReviewForm
                @close="closeReview"
            />
        </v-dialog>
        <template v-for="review in reviews.items.slice(0,reviewsCounter)">
            <div :key="review._id" class="reviews__review">
                <div class="reviews__review__user">
                    <div v-if="review && review.user" class="reviews__review__user__avatar" :style="`background-image: url(${review.user ? imageSrc(review.user.avatar) : ''})`"></div>
                    <div v-if="review && review.externalUser" class="reviews__review__user__avatar" :style="`background-image: url(${review.externalUser ? imageSrc(review.externalUser.avatar) : ''})`"></div>
                    <div style="display: flex; flex-direction: column">
                        <div style="display: flex">
                            <div class="reviews__review__user__name">
                                <template v-if="review.user">
                                    {{ review.user.firstname }} {{ review.user.lastname[0] }}.
                                </template>
                                <template v-else>
                                    {{ review.externalUser.firstname }} {{ review.externalUser.lastname[0] }}.
                                </template>
                            </div>
                            <div class="reviews__review__time">{{ review.date | moment('DD.MM.YYYY') }}</div>
                        </div>
                        <div style="display: flex; align-items: center; margin-top: 5px;">
                            <div class="reviews__review__user__raiting">{{ review.rating.overall }}</div>
                            <v-rating
                                v-model="review.rating.overall"
                                background-color="grey"
                                color="warning"
                                half-increments
                                hover
                                length="5"
                                size="15"
                                dense
                                readonly
                                style="margin-left: 5px"
                            />
                        </div>
                    </div>
                </div>
                <div class="reviews__review__text">{{ review.text }}</div>
            </div>
        </template>
        <div style="margin-top: 20px; display: flex; justify-content: center;">
        <!-- <BaseButton v-if="entities.totalCount > reviewsCounter" style="margin-right: 20px;" @click="showMoreReviews">{{ translates[langUrl].button_show[lang] }}</BaseButton>
        <BaseButton v-if="reviewsCounter > 4" @click="showLessReviews">{{ translates[langUrl].button_hide[lang] }}</BaseButton> -->
        <BaseButton @click="showAllReviews">{{ translates[langUrl].button_show[lang] }}</BaseButton>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';

    import BaseButton from '../../common/BaseButton';
    import ReviewForm from '../ReviewForm.vue';

    export default {
        name: 'Reviews',
        components: {
            BaseButton,
            ReviewForm
        },
        data: () => ({
            imageSrc,
            shownReview: false,
            reviewsCounter: 10,
            langUrl: 'reviews'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            // calculateRating(name) {
            //     let arrRating = this.entities.map(function(entity){
            //         return entity.rating[name]
            //     })
            // },
            openReview() {
                if (!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                } else {
                    this.shownReview = true
                }
            },
            closeReview() {
                this.shownReview = false
            },
            showMoreReviews() {
                this.reviewsCounter = this.reviewsCounter + 4
            },
            showLessReviews() {
                this.reviewsCounter = 4
            },
            showAllReviews() {
                this.goTo('reviews-list')
            },
            async goTo(name) {
                await this.$router.push({ name })
            },
        }
    }
</script>


<style lang="scss">
.reviews {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    &__bar {
        margin: 0 0 32px;
        width: 100%;
        min-height: 224px;
        background: #F6F7F9;
        border-radius: 12px;
        @media all and (min-width: 768px) {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            min-height: 77px;
            // padding: 0 35px;
        }
        &__item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-height: 56px;
            align-items: center;
            padding: 16px 24px;
            border-bottom: 1px solid #fff;
            &__first {
                @media all and (min-width: 768px) {
                    width: 30%;
                }
            }
            &__last {
                @media all and (min-width: 768px) {
                    width: 20%;
                }
            }
            @media all and (min-width: 768px) {
                width: 25%;
                justify-content: center;
                padding: 0;
                border-bottom: none;
                border-right: 1px solid #fff;
                &:last-of-type {
                    border-right:none;
                    padding-right: 0;
                }
            }
            &__category {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin: 0 8px 0 15px;
                &__icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px
                }
            }
            &__value {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-right: 2px;
                &__star {
                    width: 14px;
                    height: 14px;
                    margin-left: 2px
                }
            }
        }
    }
    &__review {
        padding: 40px 0;
        border-bottom: 1px solid #E5E6E9;
        &:last-of-type {
            border-bottom: none;
        }
        &__time{
            margin-left: 12px;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #93A3B4;
        }
        &__user {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 35px;
            &__avatar{
                width: 70px;
                height: 70px;
                border-radius: 50%;
                background: url(../../../assets/Andre.png) no-repeat;
                background-size: cover;
                background-position: center;
                margin-right: 20px;
            }
            &__name {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #3A4256;

            }
            &__raiting {
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #707378;
                margin-top: 5px;
            }
        }
        &__text{
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
    }
}
</style>