<template>
    <div class="right-info-block" v-if="bloggerTrip && bloggerTrip.trip && translates && translates[langUrl]">
        <!-- <DateSelect
            :items="dateSelectItems"
            item-text="label"
            item-value="value"
            :value="0"
            @change="setActiveArrival"
        /> -->
        <div class="right-info-block__prices__info no-border">
            <div class="right-info-block__prices__info__text">
                {{ translates[langUrl].tourDate[lang] }}
            </div>
            <div class="right-info-block__prices__info__value">
                {{dates}}
            </div>
        </div>
        <div class="right-info-block__prices__info">
            <div class="right-info-block__prices__info__text">
                {{ translates[langUrl].tourPrice[lang] }}
            </div>
            <div class="right-info-block__prices__info__value">
                <!-- {{ bloggerTrip.price }} ₽ -->
                <span v-html="priceFormated(bloggerTrip.price, currency)"></span>
            </div>
        </div>
        <div class="right-info-block__prices__info no-border">
            <div class="right-info-block__prices__info__text">
                <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].bloggerAward[lang] }}</div>
                <div class="right-info-block__prices__info__comment">{{ translates[langUrl].writeYourAward[lang] }}</div>
            </div>
            <!-- <div class="right-info-block__prices__info__text__value">
                20 000 ₽
            </div> -->
            <AwardTextField 
                v-model="bloggerTrip.bloggerAward"
            />
        </div>
        <div class="right-info-block__prices__info">
            <div class="right-info-block__prices__info__text">
                {{ translates[langUrl].totalPrice[lang] }}
            </div>
            <div class="right-info-block__prices__info__value weight">
                <!-- {{ priceWithAward() }} ₽ -->
                <span v-html="priceFormated(priceWithAward, currency)"></span>
            </div>
        </div>
        <div class="right-info-block__prices__info right-info-block__prices__info__text travelers no-border">
            {{ translates[langUrl].bloggerPlaces[lang] }}
            <BaseCounter
                :value="bloggerTrip.bloggerPlaces"
                :max-value="+bloggerTrip.trip.groupSizeTo || 100"
                @change="updateTraveler"
            />
        </div>
        <div class="right-info-block__prices__info">
            <div class="right-info-block__prices__info__text">
                {{ translates[langUrl].groupReward[lang] }}
            </div>
            <div class="right-info-block__prices__info__value big">
                <!-- {{ priceFormated(cost(), currency) }} ₽ -->
                <span v-html="priceFormated(totalAward, currency)"></span>
            </div>
        </div>
        <div class="right-info-block__prices__info">
            <div class="right-info-block__prices__info__text" style="width: 100%">
                <BaseButton
                    style="width: 100%;"
                    class="button-fill"
                    @click="save"
                    >{{ translates[langUrl].button_save[lang] }}</BaseButton>
                <div class="right-info-block__prices__info__comment right-info-block__prices__info__comment__button">{{ translates[langUrl].commission[lang] }}</div>
            </div>
        </div>
        <div class="right-info-block__prices__info" :class="{'no-border': !(isMobile && bloggerTrip.trip.user)}">
            <div class="right-info-block__prices__info__text" style="width: 100%">
                <BaseButton
                    style="width: 100%;"
                    @click="decline"
                    >{{ translates[langUrl].button_decline[lang] }}</BaseButton>
            </div>
        </div>
        <div v-if="isMobile" class="right-info-block__organizer">
            <template v-if="bloggerTrip.trip.organizer && bloggerTrip.trip.organizer.firstname">
                <div class="right-info-block__organizer__text">
                    <div class="right-info-block__organizer__text__name"> {{ bloggerTrip.trip.organizer.firstname }}, {{ translates[langUrl].tourAuthor[lang] }}</div>
                    <!-- <div class="right-info-block__organizer__text__raiting">4,5 <img src="../../../assets/Stars.jpg" /></div> -->
                </div>
                <div class="right-info-block__organizer__avatar" :style="`background-image: url(${ imageSrc(bloggerTrip.trip.organizer.avatar) })`"></div>
            </template>
            <template v-else-if="bloggerTrip.trip.user">
                <div class="right-info-block__organizer__text">
                    <div class="right-info-block__organizer__text__name"> {{ bloggerTrip.trip.user.firstname }}, {{ translates[langUrl].tourAuthor[lang] }}</div>
                    <!-- <div class="right-info-block__organizer__text__raiting">4,5 <img src="../../../assets/Stars.jpg" /></div> -->
                </div>
                <div class="right-info-block__organizer__avatar" :style="`background-image: url(${ imageSrc(bloggerTrip.trip.user.avatar) })`"></div>
            </template>
        </div>
        <BaseButton
            v-if="user && bloggerTrip.trip && bloggerTrip.trip.user && user._id !== bloggerTrip.trip.user._id"
            style="width: 100%"
            @click="writeToExpert">{{ translates[langUrl].buttonwriteToExpert[lang] }}</BaseButton>
    </div>
</template>

<script>
    import DateSelect from './DateSelect.vue'
    import BaseButton from '../BaseButton.vue'
    import BaseCounter from '../BaseCounter.vue'
    import AwardTextField from '../AwardTextField.vue'

    import { imageSrc, priceFormated, isMobile } from '@/helpers';
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BLOGGER_TRIP_STATUS } from '@/vars';
    import moment from 'moment'

    export default {
        name: 'Calculator',
        components: {
            DateSelect,
            BaseButton,
            BaseCounter,
            AwardTextField
        },
        props: {},
        data: () => ({
            BLOGGER_TRIP_STATUS,
            imageSrc,
            bloggerAward: 0,
            priceFormated,
            lastMove: null,
            isMobile: false,
            langUrl: 'Calculator'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('bloggerTrips', {
                bloggerTrip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                }
            }),
            dates() {
                if(this.bloggerTrip && this.bloggerTrip.start && this.bloggerTrip.finish) {
                    return `${ moment(this.bloggerTrip.start).format('DD MMM') } - ${ moment(this.bloggerTrip.finish).format('DD MMM') }`;
                }
                return '';
            },
            totalAward() {
                return +this.bloggerTrip.bloggerPlaces * +this.bloggerTrip.bloggerAward;
            },
            priceWithAward() {
                return this.bloggerTrip.bloggerPlaces * (+this.bloggerTrip.price + +this.bloggerTrip.bloggerAward);
            },
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            if(!this.bloggerTrip.bloggerAward) {
                this.bloggerTrip.bloggerAward = 0;
            }
            // store.dispatch( 'bookings/fetch', {} );
        },
        methods: {
            onResize() {
                this.isMobile = !!isMobile();
            },
            updateTraveler(value) {
                this.bloggerTrip.bloggerPlaces = value;
            },
            async writeToExpert() {
                if(this.user._id) {
                    this.$root.$emit('tripChatOpen');
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.$root.$emit('tripChatOpen');
                    });
                }
            },
            async save() {
                store.commit('bloggerTrips/SET_ENTITY', {
                    ...this.bloggerTrip,
                    status: BLOGGER_TRIP_STATUS.MODERATED
                });
                await store.dispatch('bloggerTrips/save');
                await this.$router.push({ name: 'blogger-trips-my' });
            },
            async decline() {
                store.commit('bloggerTrips/SET_ENTITY', {
                    ...this.bloggerTrip,
                    status: BLOGGER_TRIP_STATUS.DECLINED
                });
                await store.dispatch('bloggerTrips/save');
                await this.$router.push({ name: 'blogger-trips-my' });
            }
        }
    }

</script>

<style lang="scss">
.right-info-block {
    position: relative;
    max-width: 420px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 32px;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    z-index: 5;
    &__prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6px 16px;
        border-bottom: 1px solid #EBEBEB;
        @media all and (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
        }
        &__info {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            padding: 17px 0;
            border-bottom: 1px solid #EBEBEB;
            display: flex;
            justify-content: space-between;
            &.no-border {
                border-bottom: none;
            }
            &__text {
                color: #A1A4B1;
                &.bloggerAward {
                    // margin-top: 5px;
                    margin-bottom: 4px;
                }
            }
            &__value {
                color: #273155;
                &.weight {
                    font-weight: 700;
                }
                &.big {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            &__comment {
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                @media all and (max-width: 768px) {
                    margin-bottom: 4px;
                }

                &__button {
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                }
            }
        }
    }
    &__organizer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0;
        &__text {
            color: #3A4256;
            margin-left: 10px;
            &__name {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin: auto 0;
                text-align: center;
            }
        }
        &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            // background: #EBEBEB;
        }
    }
}
</style>